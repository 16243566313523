<template>
    <article id="wrap">
  
      <AppHeader theme="white">マスターFAQ</AppHeader>
  
  
      <section class="contents">
  
        <div class="contentInner pb_80">
  
  
          <div class="acd">
            <input id="check1" type="checkbox" class="acd-hidden">
            <label for="check1" class="acd-open">自分のアイコンの変え方について</label>
            <label for="check1" class="acd-close">ページ上部の丸型アイコンの『＋』をタップして、写真ライブラリor写真を撮るorファイルを選択から画像をアップロードしてください。</label>
          </div>
  
          <div class="acd">
            <input id="check2" type="checkbox" class="acd-hidden">
            <label for="check2" class="acd-open">販売申請について</label>
            <label for="check2" class="acd-close">販売申請をタップし商品情報の入力後規約に同意をチェック、内容確認後に販売申請をすることによって、販売したいしたい商品の申請を行うことが可能です。
            ステキナセカイ運営チームが申請を受理すると、トークより該当商品の販売がおこなえます。
            ※申請受理は申請日より3営業日以内に審査、受理を行います
            ※商品内容に不備、規約に違反する物がある場合はご登録いただいているメールアドレスへご連絡をいたします</label>
          </div>
  
          <div class="acd">
            <input id="check3" type="checkbox" class="acd-hidden">
            <label for="check3" class="acd-open">受注について</label>
            <label for="check3" class="acd-close">販売中の商品の受注状況の確認、発送状況の確認をすることができます。
            [すべて][未発送][発送済]のタブから全ての購入者情報、未発送の購入者情報、発送済の購入者情報が確認でき、購入者の名前をタップすると、
            注文日/注文ID/注文者名/配送者番号を確認することができます。
            また、発送が終わり次第[発送済に変更する]をタップすることによって注文者のステータスを[発送済]に変更することができます。</label>
          </div>
  
          <div class="acd">
            <input id="check4" type="checkbox" class="acd-hidden">
            <label for="check4" class="acd-open">AIマネージャーについて</label>
            <label for="check4" class="acd-close">AIマネージャーは芸能、音楽、芸術など様々な分野などマスターを手助けする敏腕なマネージャーです！
            マスターの質問に適切な回答をしてくれるでしょう！
            まずは質問をしてみましょう！</label>
          </div>
  
          <div class="acd">
            <input id="check5" type="checkbox" class="acd-hidden">
            <label for="check5" class="acd-open">参加一覧について</label>
            <label for="check5" class="acd-close">各コースに加入している人数と、人数をタップすることによって参加者情報を確認することができます。</label>
          </div>
  
          <div class="acd">
            <input id="check6" type="checkbox" class="acd-hidden">
            <label for="check6" class="acd-open">販売商品について</label>
            <label for="check6" class="acd-close">販売中の商品、過去に販売した商品の確認ができます。</label>
          </div>
  
          <div class="acd">
            <input id="check7" type="checkbox" class="acd-hidden">
            <label for="check7" class="acd-open">会員情報について</label>
            <label for="check7" class="acd-close">ご自身の会員情報の確認と、会員情報の変更ができます。</label>
          </div>
  
          <div class="acd">
            <input id="check8" type="checkbox" class="acd-hidden">
            <label for="check8" class="acd-open">購入履歴について</label>
            <label for="check8" class="acd-close">過去にご自身で購入した商品の確認ができます。</label>
          </div>
  
          <div class="acd">
            <input id="check9" type="checkbox" class="acd-hidden">
            <label for="check9" class="acd-open">デジコンについて</label>
            <label for="check9" class="acd-close">投稿した写真一覧が確認できます。</label>
          </div>
  
          <div class="acd">
            <input id="check10" type="checkbox" class="acd-hidden">
            <label for="check10" class="acd-open">アルバムについて</label>
            <label for="check10" class="acd-close">投稿した写真一覧が確認できます。</label>
          </div>
  
          <div class="acd">
            <input id="check11" type="checkbox" class="acd-hidden">
            <label for="check11" class="acd-open">ステキナセカイとは</label>
            <label for="check11" class="acd-close">チュートリアルを確認することができます。</label>
          </div>
  
          <div class="acd">
            <input id="check11" type="checkbox" class="acd-hidden">
            <label for="check11" class="acd-open">お知らせ</label>
            <label for="check11" class="acd-close">アップデートのお知らせやメンテナンスのお知らせなどステキナセカイ運営からのお知らせが確認できます。</label>
          </div>
  
          <div class="acd">
            <input id="check11" type="checkbox" class="acd-hidden">
            <label for="check11" class="acd-open">お問い合わせ</label>
            <label for="check11" class="acd-close">ステキナセカイ運営へのお問い合わせができます。</label>
          </div>
  
          <div class="acd">
            <input id="check12" type="checkbox" class="acd-hidden">
            <label for="check12" class="acd-open">ログアウト</label>
            <label for="check12" class="acd-close">ログアウトができます。</label>
          </div>
  
          <div class="acd">
            <input id="check13" type="checkbox" class="acd-hidden">
            <label for="check13" class="acd-open">投稿方法について</label>
            <label for="check13" class="acd-close">トークページ下部[メッセージ投稿]をタップし、メッセージ欄に投稿内容を記載し[送信]より投稿を行なってください。</label>
          </div>
  
          <div class="acd">
            <input id="check14" type="checkbox" class="acd-hidden">
            <label for="check14" class="acd-open">画像/動画の添付方法について</label>
            <label for="check14" class="acd-close">トークページ下部[メッセージ投稿]をタップし、メッセージ欄上部のアイコンより一番左のアイコンをタップし画像を添付してください。
            ※一度に投稿できる画像は4枚までとなっております
            ※動画はステキナセカイ内で適切なサイズに変換が必要な為、長尺の動画は変換に時間がかかる場合がございます</label>
          </div>
  
          <div class="acd">
            <input id="check15" type="checkbox" class="acd-hidden">
            <label for="check15" class="acd-open">デジタルコンテンツ販売について</label>
            <label for="check15" class="acd-close">ステキナセカイデは投稿する画像に対し値段をつけ、販売をすることができます。
            画像を添付の際、該当の画像下部の[無料]バーをタップし[有料]にすることによって[値段を決める]より任意の金額に設定し投稿することで、販売が可能です。
            ※購入者のみ画像がトーク上、[わたしのこと]ページ[デジコン]で確認が可能です
            ※購入されていないお客様にはトーク上でモザイク処理がされ表示されます</label>
          </div>
  
          <div class="acd">
            <input id="check16" type="checkbox" class="acd-hidden">
            <label for="check16" class="acd-open">YouTube動画の投稿について</label>
            <label for="check16" class="acd-close">トークページ下部[メッセージ投稿]をタップし、メッセージ欄上部のアイコンより左から2番目のアイコンをタップし、該当のリンクを添付してください。
            ※YouTube上で限定公開に設定した動画や、限定配信のリンクを添付することによって、ステキナセカイで限定動画を配信することが可能です</label>
          </div>
  
          <div class="acd">
            <input id="check17" type="checkbox" class="acd-hidden">
            <label for="check17" class="acd-open">商品販売投稿について</label>
            <label for="check17" class="acd-close">トークページ下部[メッセージ投稿]をタップし、メッセージ欄上部のアイコンより1番右のアイコンをタップし、[お知らせする商品を決める]から該当商品を選択し送信してください。
            ※投稿は販売期間中トークページ上部に固定されます
            ※商品販売は事前に[わたしのこと]ページ、[販売申請]から申請が必要となります</label>
          </div>
  
        </div><!--.contentInner-->
  
      </section><!--.contents-->
  
  
      <AppFooter fixed theme="yellow"></AppFooter>
  
    </article>
  </template>
  
  <script>
  import AppHeader from '@/components/AppHeader';
  import AppFooter from '@/components/AppFooter';
  export default {
    name: 'FAQ',
    components: {AppHeader, AppFooter}
  }
  </script>
  